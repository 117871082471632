<template>
    <div class="" style="">
        <div class="image-section" v-if="!isLoading">
            <LazyImage  :src="getImage" v-if="uploadImage" :key="getImage" alt="User Profile Image" class="UserImage"    style="height: 64px; width: 64px;object-fit: cover;"/>
                <b-avatar variant="primary" :text="textFirstLetter"  v-else size="64px" style="background-color: #4d1b7e;"></b-avatar>
               
                  <div class="change-profile-btn" style="" @click="inputUploadFile()" v-if="isUploadImage"><i class="fas fa-camera"></i></div>
                  </div>
                
            <div class="d-flex align-items-center justify-content-center"  v-else style="color:var(--primary-color);height:64px; width:64px;">
                <b-spinner small></b-spinner>
            </div>
            <input v-if="isUploadImage" type="file" name="change-image" id="" style="display:none" ref="changeImageInput" accept="image/png,image/gif,image/jpeg,image/jpg,image/svg+xml" @change="changeImage">
            </div>

</template>
<script>
export default{
    props:{
        uploadImage:String,
        isUpload:Boolean,
        text:String,
        isLoading:Boolean
    },
    data(){
        return{
            imageFile:this.uploadImage
        }
       
    },
    computed:{
        isUploadImage(){
           return this.isUpload?this.isUpload:false
        },
        textFirstLetter(){
           
            return this.text?this.text.trim().charAt(0):'Q';
            
        },
        getImage(){

            return this.imageFile!=null?this.imageFile:this.uploadImage?this.uploadImage:`https://images.quizell.com/website/default_start_page.png`
        }
       
    },
    methods:{
        deleteImagePreview(){
            this.imageFile=null;
        },
        inputUploadFile(){
            this.$refs.changeImageInput.click()
        },
        changeImage(e){
            const allowedExtension = ['image/jpeg', 'image/jpg', 'image/png','image/gif','image/svg+xml'];
            if(e.target.files[0])
           if(allowedExtension.indexOf(e.target.files[0].type)>-1)
           {
           this.imageFile= URL.createObjectURL(e.target.files[0]);
           this.$emit('change',e.target.files[0])
           }
            else
            this.$toasted.show("File must be an image", {
                              theme: "toasted-primary",
                              position: "bottom-center",
                              duration: 2000,
                            });
        }
    }
}
</script>
<style scoped>
.change-profile-btn{
    position: absolute;
    width:100%;
    top: 0;
    /* transform: translate(0%, -50%); */
    background: #0f06184f;
    color: white;
    text-align: center;
    height: 64px;
    line-height: 2;
    cursor:pointer;
    display:none;
    opacity:0;
    transition: 0.3s ease all;
}
.image-section{
    position: relative;height: 64px; width: 64px;overflow: hidden; border-radius: 50%;
}
.image-section:hover .change-profile-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    opacity: 1;
}
</style>